import { useAppDispatch, useAppSelector } from '@/hooks/store';
import {
  setCustomPriceInputRowOnBlur,
  setCustomPriceInputRowOnChange,
  softDeleteLocationCustomPrice,
} from '@/state/item';
import { selectNextRowID, selectPrevRowID } from '@/state/item/selectors';
import { TableRefOptions } from '@/state/item/types';
import { selectEditMode } from '@/state/ui/selectors';
import {
  centsToDollars,
  createChangeEventPayload,
} from '@/utils/CalculatorUtils';
import { ChangeEvent, FocusEvent, KeyboardEvent } from 'react';
import { Text, Input, Close, ThemeUICSSObject } from 'theme-ui';

const numberDataStyle: ThemeUICSSObject = {
  fontFamily: 'SFMono-Regular, monospace',
  fontWeight: '0',
  fontSize: '1',
  lineHeight: '1',
  textAlign: 'right',
  pr: '5',
};

const inputStyle: ThemeUICSSObject = {
  ml: '2',
  borderRadius: '2px',
  borderColor: 'backgroundMediumBorder',
  fontFamily: 'SFMono-Regular',
  fontWeight: '0',
  fontSize: '1',
  lineHeight: '1',
  textAlign: 'right',
};

interface ItemCustomPricingTableDataRowProps {
  locationName: string;
  locationID: string;
  warehouseName: string;
  purchasePriceCents: number;
  landedCostCents: number | null;
  currentMarginPercent: string;
  currentCustomPriceDollars: string;
  newMarginPercent: string;
  newCustomPriceDollars: string;
  softDeleted: boolean;
}

const ItemCustomPricingTableDataRow = ({
  locationName,
  locationID,
  warehouseName,
  purchasePriceCents,
  landedCostCents,
  currentMarginPercent,
  currentCustomPriceDollars,
  newMarginPercent,
  newCustomPriceDollars,
  softDeleted,
}: ItemCustomPricingTableDataRowProps) => {
  const editMode = useAppSelector(selectEditMode);
  const nextRowID = useAppSelector(
    selectNextRowID(locationID, TableRefOptions.CUSTOM_PRICES),
  );
  const prevRowID = useAppSelector(
    selectPrevRowID(locationID, TableRefOptions.CUSTOM_PRICES),
  );
  const dispatch = useAppDispatch();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const payload = createChangeEventPayload(event, locationID);
    dispatch(setCustomPriceInputRowOnChange(payload));
  };
  const handleBlur = (event: ChangeEvent<HTMLInputElement>) => {
    const payload = createChangeEventPayload(event, locationID);
    dispatch(setCustomPriceInputRowOnBlur(payload));
  };
  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };
  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const { key, shiftKey, currentTarget } = event;
    if (key === 'Enter') {
      const rowID = shiftKey ? prevRowID : nextRowID;
      const element = document.getElementById(`${rowID}-${currentTarget.name}`);
      element?.focus();
    }
  };
  const handleClickClose = () => {
    dispatch(softDeleteLocationCustomPrice(locationID));
  };

  return !softDeleted ? (
    <tr sx={{ height: '48px' }}>
      <td sx={{ px: '3' }}>
        <Text variant="text.paragraphLg">{locationName}</Text>
      </td>
      <td>
        <Text variant="text.paragraphMd">{warehouseName}</Text>
      </td>
      <td sx={numberDataStyle}>
        <Text>{centsToDollars(purchasePriceCents)}</Text>
      </td>
      <td sx={numberDataStyle}>
        <Text>{centsToDollars(landedCostCents)}</Text>
      </td>
      <td sx={numberDataStyle}>
        {editMode ? (
          <Input
            sx={inputStyle}
            value={newMarginPercent}
            id={`${locationID}-newMarginPercent`}
            name="newMarginPercent"
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onKeyDown={handleKeyDown}
          />
        ) : (
          <Text>{currentMarginPercent}</Text>
        )}
      </td>
      <td sx={{ ...numberDataStyle, bg: 'backgroundWeakest' }}>
        {editMode ? (
          <Input
            sx={{ ...inputStyle, bg: 'background' }}
            value={newCustomPriceDollars}
            id={`${locationID}-newCustomPriceDollars`}
            name="newCustomPriceDollars"
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onKeyDown={handleKeyDown}
          />
        ) : (
          <Text>{currentCustomPriceDollars}</Text>
        )}
      </td>
      {!editMode ? (
        <></>
      ) : (
        <td sx={{ bg: 'backgroundWeakest' }}>
          <Close sx={{ cursor: 'pointer' }} onClick={handleClickClose} />
        </td>
      )}
    </tr>
  ) : (
    <></>
  );
};

export default ItemCustomPricingTableDataRow;
