import { PricingGetItemForEditQuery } from '@/generated/graphql-operations';

export interface ItemState {
  itemResponseData: PricingGetItemForEditQuery | null;
  warehouses: Warehouse[] | null;
  warehousePriceInputRows: WarehousePriceInputRow[];
  customPriceLocationSearchResults: LocationSearchResult[];
  customPriceInputRows: CustomPriceInputRow[];
  isLoading: boolean;
  isSaving: boolean;
  isSearching: boolean;
  error: string | null;
}

export interface WarehousePriceInputRow {
  warehouseID: string;
  warehouseName: string;
  itemID: string | undefined;
  // Display Values on Edit Mode ~~>
  newLandedCost: string;
  newMarginPercent: string;
  newMarginDollars: string;
  newSalePrice: string;
  // <~~ Display Values on Edit Mode
  newMarginPercentFloat: number;
  newMarginCents: number;
  newLandedCostCents: number;
  newSalePriceCents: number | null;
  newLandedCostCurrency: string;
  newSalePriceCurrency: string;
  purchasePriceCents: number;
  currentLandedCostCents: number | null | undefined;
  currentLandedCostCurrency: string | null | undefined;
  currentMarginPercent: string;
  currentMarginDollars: string;
  currentSalePriceCents: number | null | undefined;
  currentSalePriceCurrency: string | null | undefined;
  inputRowChanged: boolean;
}

export interface InputRowPayload {
  // Warehouse or Location UUID depending on table
  uuid: string;
  eventReduced: { inputType: string; value: string };
}

export interface Warehouse {
  id: string;
  name: string;
}

export interface LocationSearchResult {
  locationID: string;
  locationName: string;
  warehouseID: string;
  warehouseName: string;
  selected: boolean;
}

export interface CustomPriceInputRow {
  locationID: string;
  locationName: string;
  warehouseID: string;
  warehouseName: string;
  purchasePriceCents: number;
  landedCostCents: number | null;
  currentMarginPercent: string;
  currentMarginDollars: string;
  currentCustomPriceCents: number | null;
  currentCustomPriceDollars: string;
  currentCustomPriceCurrency: string;
  newMarginPercent: string;
  newMarginDollars: string;
  newMarginPercentFloat: number;
  newCustomPriceCents: number | null;
  newCustomPriceDollars: string;
  inputRowChanged: boolean;
  softDeleted: boolean;
  prevSaved: boolean;
}

export enum TableRefOptions {
  WAREHOUSE_PRICES = 'WAREHOUSE_PRICES',
  CUSTOM_PRICES = 'CUSTOM_PRICES',
}
